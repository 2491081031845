<template>
  <iframe
    :src="iframeLink"
    frameborder="0"
  ></iframe>
</template>

<script>
  import mushroom from "../../../service/mushroom.api.def"

  export default {
    data() {
      return {
        moduleName: "Survey",
        surveyId: null,
        iframeLink: null,
        viewMode: "create"
      };
    },
    methods: {
    },
    async created() {
      if (this.$router.currentRoute.name == "Admin-" + this.moduleName + "-createSurvey") {
        this.viewMode = "create";
      } else if (this.$router.currentRoute.name == "Admin-" + this.moduleName + "-updateSurvey") {
        this.viewMode = "update";
      } else {
        this.viewMode = "view";
      }
    },
    mounted: async function () {
      let callbackUrlPath = location.protocol + "//" + location.host + "/reload.html?callbackUrl=" + this.$router.currentRoute.query.callbackUrl;
      let hashCodeObject = {
        prefillData: "",
        prefillId: this.$router.currentRoute.query.prefillId,
        privateKey: this.$router.currentRoute.query.privateKey,
        callbackUrl: callbackUrlPath,
        appCode: this.$router.currentRoute.query.appCode,
        env: this.$router.currentRoute.query.env,
      }
      let responseHashCode = await mushroom.survey.generateHashCodeAsync(hashCodeObject);
      this.iframeLink = MyConfig.surveyDomain + 's/' + this.$router.currentRoute.params.id +
        '?prefillData=' +
        '&prefillId=' + this.$router.currentRoute.query.prefillId +
        '&callbackUrl=' + callbackUrlPath +
        '&appCode=' + this.$router.currentRoute.query.appCode +
        '&env=' + this.$router.currentRoute.query.env +
        '&hashCode=' + responseHashCode.result +
        '&viewMode=' + this.viewMode;
      if (this.viewMode != 'create') {
         this.iframeLink += '&id=' + this.$router.currentRoute.query.id
      }
    }
  }
</script>
<style scoped>
  iframe {
    width: 100%;
    height: calc(100vh - 61px);
  }
</style>
